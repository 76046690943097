import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import "./comp.css";

const btn = {
  play: {
    eng: "Play",
    spa: "Jugar",
  },
  pause: {
    eng: "Pause",
    spa: "Pausar",
  },
  clear: {
    eng: "Clear",
    spa: "Limpiar",
  },
};

export default class GameBtn extends React.Component {
  render() {
    return (
      <div>
        <ButtonGroup aria-label="Basic example" className="button_g">
          <Button
            onClick={this.props.playBtn}
            disabled={!this.props.isSeeded}
            className="reg-btn"
          >
            {btn["play"][this.props.activeLang]}
          </Button>
          <Button
            onClick={this.props.pauseBtn}
            disabled={!this.props.isPlaying}
            className="reg-btn"
          >
            {btn["pause"][this.props.activeLang]}
          </Button>
          <Button
            onClick={this.props.clearBtn}
            disabled={!this.props.isSeeded || this.props.isPlaying}
            className="reg-btn"
          >
            {btn["clear"][this.props.activeLang]}
          </Button>
        </ButtonGroup>
      </div>
    );
  }
}

import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "./comp.css";
import { Form, Button } from "react-bootstrap";
import * as firebase from "firebase";

const top = {
  eng: "Name",
  spa: "Nombre",
};

const sub_top = {
  eng: "Enter your name",
  spa: "Ingrese su nombre",
};

const mid = {
  eng: "Email address",
  spa: "Correo electrónico",
};

const sub_mid = {
  eng: "Enter email",
  spa: "Ingrese su correo electrónico",
};

const mid2 = {
  eng: "We'll never share your email with anyone else.",
  spa: "Nunca compartiremos su correo electrónico.",
};

const bot = {
  eng: "Message",
  spa: "Mensaje",
};

const sub_bot = {
  eng: "Type you message here...",
  spa: "Escriba su mensaje aquí...",
};

const btn = {
  eng: "Submit",
  spa: "Enviar",
};

export default class ContactForm extends React.Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      message: "",
      isVerified: false,
    };
  }

  render() {
    return (
      <Form className="contact-form" onSubmit={this.handleSubmit}>
        <Form.Group controlId="contact.Name">
          <Form.Label>{top[this.props.activeLang]}</Form.Label>
          <Form.Control
            type="name"
            value={this.state.name}
            placeholder={sub_top[this.props.activeLang]}
            onChange={this.handleNameChange}
          />
        </Form.Group>
        <Form.Group controlId="contact.Email">
          <Form.Label>{mid[this.props.activeLang]}</Form.Label>
          <Form.Control
            type="email"
            value={this.state.email}
            placeholder={sub_mid[this.props.activeLang]}
            onChange={this.handleEmailChange}
          />
          <Form.Text className="text-muted">
            {mid2[this.props.activeLang]}
          </Form.Text>
        </Form.Group>
        <Form.Group controlId="contact.Textarea">
          <Form.Label>{bot[this.props.activeLang]}</Form.Label>
          <Form.Control
            as="textarea"
            rows="4"
            value={this.state.message}
            onChange={this.handleMessageChange}
            placeholder={sub_bot[this.props.activeLang]}
          />
        </Form.Group>
        <ReCAPTCHA
          sitekey="6LcV_M4ZAAAAAEfAv76goVzlvR63___gt6Qako5B"
          onChange={this.handleCaptcha}
        />
        <Button className="reg-btn" type="submit">
          {btn[this.props.activeLang]}
        </Button>
      </Form>
    );
  }

  handleNameChange = (e) => {
    this.setState({ name: e.target.value });
  };

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };

  handleMessageChange = (e) => {
    this.setState({ message: e.target.value });
  };

  handleCaptcha = (e) => {
    this.setState({ isVerified: true });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.isVerified) {
      console.log(this.state);
      firebase.database().ref().child("messages").push(this.state);
      this.clearForm();
    }
  };

  clearForm = () => {
    this.setState({
      name: "",
      email: "",
      message: "",
      isVerified: false,
    });
  };
}

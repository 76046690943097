import React from "react";
import { ResponsiveEmbed, Image } from "react-bootstrap";
import "./comp.css";
import hvid_eng from "../images/video_high_eng.mp4";
import hvid_spa from "../images/video_high_spa.mp4";
import lvid_eng from "../images/video_low_eng.mp4";
import lvid_spa from "../images/video_low_spa.mp4";
import thumb_eng from "../images/Thumb_eng.png";
import thumb_spa from "../images/Thumb_spa.png";

export default class ResponsiveVideo extends React.Component {
  constructor() {
    super();
    this.state = {
      isVideoLoaded: false,
    };
  }

  render() {
    let hvid = this.props.activeLang === "eng" ? hvid_eng : hvid_spa;
    let lvid = this.props.activeLang === "eng" ? lvid_eng : lvid_spa;
    let vid = window.innerWidth >= 720 ? hvid : lvid;
    let thumb = this.props.activeLang === "eng" ? thumb_eng : thumb_spa;
    return (
      <div>
        <Image
          fluid
          src={thumb}
          className="video-thumb"
          style={{ opacity: this.state.isVideoLoaded ? 0 : 1 }}
        />
        <ResponsiveEmbed aspectRatio="16by9">
          <video
            autoPlay
            controls
            src={vid}
            type="video/mp4"
            className="vid"
            onLoadedData={this.onLoadedData}
            style={{ opacity: this.state.isVideoLoaded ? 1 : 0 }}
          />
        </ResponsiveEmbed>
      </div>
    );
  }

  onLoadedData = () => {
    this.setState({
      isVideoLoaded: true,
    });
  };
}

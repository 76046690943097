import React from "react";
import { Tab, Row, Col, Nav, Image } from "react-bootstrap";
import "./comp.css";
import img1 from "../images/gameoflife/gameoflifesurvive.png";
import img2 from "../images/gameoflife/gameoflifedie.png";
import img3 from "../images/gameoflife/gameoflifeborn.png";
import img4 from "../images/deadlock/deadlocksurvive.png";
import img5 from "../images/deadlock/deadlockdie.png";
import img6 from "../images/deadlock/deadlockborn.png";
import img7 from "../images/seeds/seedsborn.png";
import img8 from "../images/highlife/highlifesurvive.png";
import img9 from "../images/highlife/highlifedie.png";
import img10 from "../images/highlife/highlifeborn.png";

const im_obj = {
  sur_gl: img1,
  die_gl: img2,
  born_gl: img3,
  sur_dl: img4,
  die_dl: img5,
  born_dl: img6,
  born_s: img7,
  sur_hl: img8,
  die_hl: img9,
  born_hl: img10,
};

export default class RuleNav extends React.Component {
  render() {
    return (
      <Tab.Container
        id="sub_rule"
        defaultActiveKey={this.props.tabObj[0].eventK}
      >
        <Row>
          <Col sm={3}>
            <Nav variant="pills" className="flex-column rule_col">
              {this.props.tabObj.map((obj) => (
                <Nav.Item className="rule_navit" key={`item-${obj.eventK}`}>
                  <Nav.Link className="rule_tab" eventKey={obj.eventK}>
                    {obj.name}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              {this.props.tabObj.map((obj) => (
                <Tab.Pane eventKey={obj.eventK} key={`img-${obj.eventK}`}>
                  <Row className="justify-content-center">
                    <Col md={6} xs={10}>
                      <Image src={im_obj[obj.img]} fluid className="rule_img" />
                    </Col>
                  </Row>
                  <Row className="rule_row">
                    <p className="rule_text">{obj.text}</p>
                  </Row>
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    );
  }
}

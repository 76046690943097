import React from "react";
import "./comp.css";
import { Jumbotron, Button, Col, Row, Image } from "react-bootstrap";
import mic from "../images/microfono.png";

const btn = {
  eng: "Play",
  spa: "Jugar",
};

const title = {
  eng: "Plant your own world",
  spa: "Siembra tu propio mundo",
};

export default class Jumbo extends React.Component {
  render() {
    return (
      <Jumbotron>
        <Row className="justify-content-center">
          <Col lg={8} md={7} xs={6}>
            <Row className="vertical-center">
              <Col className="jumbo-row">
                <h1 className="jumbo-title">{title[this.props.activeLang]}</h1>
                <p>
                  <Button className="reg-btn" onClick={this.props.clickFn}>
                    {btn[this.props.activeLang]}
                  </Button>
                </p>
              </Col>
            </Row>
          </Col>
          <Col lg={4} md={5} xs={6}>
            <Image src={mic} fluid />
          </Col>
        </Row>
      </Jumbotron>
    );
  }
}

import React from "react";
import { Modal, Button } from "react-bootstrap";
import ResponsiveVideo from "./respvid";
import { Link } from "react-router-dom";
import "./comp.css";

const title = {
  eng: "Make sure to check out the rules first!",
  spa: "¡Lea las reglas antes de jugar!",
};

const close_btn = {
  eng: "Close",
  spa: "Cerrar",
};

const rule_btn = {
  eng: "Rules",
  spa: "Reglas",
};

const play_btn = {
  eng: "Play",
  spa: "Jugar",
};

export default class Mod extends React.Component {
  render() {
    return (
      <Modal size="lg" show={this.props.show} onHide={this.props.hideFn}>
        <Modal.Header closeButton>
          <Modal.Title>{title[this.props.activeLang]}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ResponsiveVideo activeLang={this.props.activeLang} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.hideFn}>
            {close_btn[this.props.activeLang]}
          </Button>
          <Link to="/rules">
            <Button className="reg-btn" onClick={this.props.hideFn}>
              {rule_btn[this.props.activeLang]}
            </Button>
          </Link>
          <Button className="reg-btn" onClick={this.props.hideFn} href="/game">
            {play_btn[this.props.activeLang]}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

import React from "react";
import "./comp.css";
import { Row } from "react-bootstrap";

const mouse_msg = {
  eng: "You can also seed with your mouse!",
  spa: "También puedes sembrar con tu mouse!",
};

class Box extends React.Component {
  render() {
    return (
      <div
        className={this.props.boxClass}
        style={{ background: this.props.color }}
        onClick={() => this.props.manualSeed(this.props.row, this.props.col)}
        onMouseEnter={this.hoverEnter}
        onMouseLeave={this.hoverLeave}
      />
    );
  }

  hoverEnter = (e) => {
    e.target.style.background = "rgb(88, 88, 250)";
  };

  hoverLeave = (e) => {
    e.target.style.background = this.props.color;
  };
}

class Grid extends React.Component {
  render() {
    let disp_arr = [];
    //console.log(this.props.game_arr);
    for (let i = 0; i < this.props.rows; i++) {
      for (let j = 0; j < this.props.cols; j++) {
        let id_str = i + "_" + j;
        let loc_col = this.props.gen_col[this.props.game_arr[i][j]];
        //console.log(loc_col);
        disp_arr.push(
          <Box
            boxClass="box"
            color={loc_col}
            id={id_str}
            key={id_str}
            row={i}
            col={j}
            manualSeed={this.props.manualSeed}
          />
        );
      }
    }
    return (
      <div>
        <Row className="justify-content-center">
          <div className="grid" style={{ width: this.props.cols * 23 + 9 }}>
            {disp_arr}
          </div>
        </Row>
        <Row>
          <p className="mouse-msg">{mouse_msg[this.props.activeLang]}</p>
        </Row>
      </div>
    );
  }
}

export default Grid;

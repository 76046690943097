import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import "./index.css";
import * as firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyDq5RGcSG958CousTq1uHGw2lAmNrFb2JE",
  authDomain: "cellularseeds1.firebaseapp.com",
  databaseURL: "https://cellularseeds1.firebaseio.com",
  projectId: "cellularseeds1",
  storageBucket: "cellularseeds1.appspot.com",
  messagingSenderId: "805858728362",
  appId: "1:805858728362:web:df7fa53f79bbb825a6b3d3",
  measurementId: "G-P4N8KWZMJB",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

ReactDOM.render(<App />, document.getElementById("app"));

import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import "./comp.css";

const btn = {
  rec: {
    eng: "Record",
    spa: "Grabar",
  },
  sav: {
    eng: "Save",
    spa: "Guardar",
  },
  seed: {
    eng: "Seed",
    spa: "Sembrar",
  },
};

export default class AudioBtn extends React.Component {
  render() {
    return (
      <div>
        <ButtonGroup aria-label="Basic example" className="button_g">
          <Button
            onClick={this.props.start}
            disabled={this.props.isRecording}
            className="reg-btn"
          >
            {btn["rec"][this.props.activeLang]}
          </Button>
          <Button
            onClick={this.props.stop}
            disabled={!this.props.isRecording}
            className="reg-btn"
          >
            {btn["sav"][this.props.activeLang]}
          </Button>
          <Button
            onClick={this.props.gridSeed}
            disabled={this.props.c_energy === 0 || this.props.isPlaying}
            className="reg-btn"
          >
            {btn["seed"][this.props.activeLang]}
          </Button>
        </ButtonGroup>
      </div>
    );
  }
}

import React from "react";
import "./comp.css";

export default class StaticBox extends React.Component {
  render() {
    return (
      <div
        className={this.props.boxClass}
        style={{ background: this.props.color }}
      />
    );
  }
}

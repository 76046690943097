import React from "react";
import "./comp.css";
import { Image } from "react-bootstrap";

export default class ImText extends React.Component {
  render() {
    return (
      <div className="im-text">
        <Image
          src={this.props.img}
          fluid
          roundedCircle
          width={100}
          height={100}
          className="d-inline-block"
          onClick={this.clickHandle}
        />
        <h6 className="d-inline-block tag">{this.props.tag}</h6>
      </div>
    );
  }

  clickHandle = () => {
    window.open(this.props.hl);
  };
}

import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Game from "./pages/game";
import Home from "./pages/home";
import Rules from "./pages/rules";

class App extends Component {
  constructor() {
    super();
    this.state = {
      activeLang: "eng",
    };
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => (
              <Home
                {...props}
                activeLang={this.state.activeLang}
                langSwitch={this.langSwitch}
              />
            )}
          />
          <Route
            exact
            path="/game"
            render={(props) => (
              <Game
                {...props}
                activeLang={this.state.activeLang}
                langSwitch={this.langSwitch}
              />
            )}
          />
          <Route
            exact
            path="/rules"
            render={(props) => (
              <Rules
                {...props}
                activeLang={this.state.activeLang}
                langSwitch={this.langSwitch}
              />
            )}
          />
        </Switch>
      </Router>
    );
  }

  langSwitch = (nLang) => {
    this.setState({
      activeLang: nLang,
    });
  };
}

export default App;

import React from "react";
import { Navbar, Nav, Form } from "react-bootstrap";
import { BsFillHouseDoorFill, BsFillStarFill, BsBook } from "react-icons/bs";
import { Link } from "react-router-dom";
import "./comp.css";
import logo from "../images/logo.png";

const labs = {
  home: {
    eng: "Home",
    spa: "Home",
  },
  game: {
    eng: "Game",
    spa: "Juego",
  },
  rules: {
    eng: "Rules",
    spa: "Reglas",
  },
  lang: {
    eng: "Language",
    spa: "Idioma",
  },
};

class Navs extends React.Component {
  render() {
    let h_lab = labs["home"][this.props.activeLang];
    let g_lab = labs["game"][this.props.activeLang];
    let r_lab = labs["rules"][this.props.activeLang];
    let l_lab = labs["lang"][this.props.activeLang];
    return (
      <Navbar className="nav_bar" expand="md">
        <Navbar.Brand href="/">
          <img
            alt=""
            src={logo}
            width="50"
            height="50"
            className="d-inline-block align-top"
          />{" "}
        </Navbar.Brand>
        <Navbar.Brand>Cellular Seeds</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link as={Link} to="/">
              <BsFillHouseDoorFill className="icon mid" />
              {h_lab}
            </Nav.Link>
            <Nav.Link as={Link} to="/game">
              <BsFillStarFill className="icon mid" />
              {g_lab}
            </Nav.Link>
            <Nav.Link as={Link} to="/rules">
              <BsBook className="icon mid" />
              {r_lab}
            </Nav.Link>
            <Form inline className="mr-auto">
              <Form.Control
                as="select"
                className="icon"
                onChange={this.langChange}
                defaultValue="lang"
              >
                <option value="lang" disabled>
                  {l_lab}
                </option>
                <option value="eng">ENG</option>
                <option value="spa">SPA</option>
              </Form.Control>
            </Form>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
  langChange = (e) => {
    this.props.langSwitch(e.target.value);
  };
}

export default Navs;

import React from "react";
import "./pages.css";
import Navs from "../components/nav_var";
import ResponsiveVideo from "../components/respvid";
import RuleComb from "../components/rulecomb";
import { Row, Col, Container } from "react-bootstrap";

const title1 = {
  eng: "How does it work?",
  spa: "Cómo funciona?",
};

const title2 = {
  eng: "Rules",
  spa: "Reglas",
};

export default class Rules extends React.Component {
  render() {
    return (
      <div key="main-div">
        <Navs
          activeLang={this.props.activeLang}
          langSwitch={this.props.langSwitch}
        />
        <Container className="container-t1 divisor" fluid>
          <Row className="text-row">
            <h3 className="rule-label">{title1[this.props.activeLang]}</h3>
          </Row>
          <Row className="justify-content-center">
            <Col lg={8} sm={10} xs={12}>
              <ResponsiveVideo activeLang={this.props.activeLang} />
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row className="text-row">
            <h3 className="rule-label">{title2[this.props.activeLang]}</h3>
          </Row>
          <Row className="justify-content-center">
            <Col md={10} xs={12} className="game_tab">
              <RuleComb activeLang={this.props.activeLang} />
            </Col>
          </Row>
        </Container>
        <Container className="rule-foot">
          <Row>
            <Col xs={12}>
              <footer>
                <p>© 2020 by Felipe Areces</p>
              </footer>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

import React from "react";
import Navs from "../components/nav_var";
import Jumbo from "../components/jumbo";
import ImText from "../components/im_txt";
import Mod from "../components/mod";
import { Container, Col, Row } from "react-bootstrap";
import "./pages.css";
import Felipe from "../images/FelipeAreces.jpg";
import Alejandra from "../images/fotoali.jpg";
import ContactForm from "../components/contact_form";

const l_ale = "https://www.alejandramarinaro.com/";

const first_text = {
  eng: (
    <Row className="text-row">
      <h3 className="subtitle">About the Project</h3>
      <p align="justify">
        Generate any sound,{" "}
        <span style={{ color: "#8c52ff" }}>sing, talk or shout</span>. This game{" "}
        <span style={{ color: "#8c52ff" }}>
          measures the energy of your message
        </span>{" "}
        and uses it as input for a random number generating algorithm to create
        an initial seed configuration on your grid.{" "}
        <span style={{ color: "#8c52ff" }}>
          These seeds can be in two states: dead or alive
        </span>{" "}
        , and they evolve across generations following strict mathematical
        rules.{" "}
        <span style={{ color: "#8c52ff" }}>
          In each generation the newborn cells change color
        </span>{" "}
        so you can follow them and see their destiny.
      </p>
      <p align="justify">
        <span style={{ color: "#8c52ff" }}>
          According to the cellular automata you select
        </span>{" "}
        your world will evolve in a unique fashion as they are governed by
        different rules. These automata have the ability of generating many
        types of worlds: eternal, ephemeral or in permanent evolution.
      </p>
      <p align="justify">
        <span style={{ color: "#8c52ff" }}>You sow the initial seed</span> of
        the system using the{" "}
        <span style={{ color: "#8c52ff" }}>energy of your voice</span> and a{" "}
        <span style={{ color: "#8c52ff" }}>cellular automaton</span> will show
        you the evolution of the{" "}
        <span style={{ color: "#8c52ff" }}>world you chose to create</span>.
      </p>
      <p align="justify" style={{ color: "#8c52ff" }}>
        Are you ready to see your future?
      </p>
    </Row>
  ),
  spa: (
    <Row className="text-row">
      <h3 className="subtitle">El Proyecto</h3>
      <p align="justify">
        Generá un sonido,{" "}
        <span style={{ color: "#8c52ff" }}>cantá, hablá, gritá</span>, solo o en
        grupo. Este juego{" "}
        <span style={{ color: "#8c52ff" }}>mide la energía del sonido</span> y a
        través de un algorítmo la convierte en datos que generan{" "}
        <span style={{ color: "#8c52ff" }}>las semillas de tu mundo</span> en
        una grilla.{" "}
        <span style={{ color: "#8c52ff" }}>
          Esas semillas tienen dos estados: vivas o muertas
        </span>{" "}
        y evolucionarán a lo largo de las generaciones.{" "}
        <span style={{ color: "#8c52ff" }}>
          En cada generación las semillas son de un color diferente
        </span>{" "}
        para que puedas seguirlas y conocer su destino.
      </p>
      <p align="justify">
        <span style={{ color: "#8c52ff" }}>
          En función del autómata celular que elijas
        </span>{" "}
        para jugar,{" "}
        <span style={{ color: "#8c52ff" }}>
          tu mundo evolucionará de una forma distinta
        </span>{" "}
        porque cada uno está gobernado por reglas propias. Los autómatas te
        proponen mundos eternos, efímeros y otros en permanente evolución.
      </p>
      <p align="justify">
        Sembrá la semilla inicial de tu mundo usando la energía de tu voz y el
        autómata cellular que eligas estará a cargo de mostrarte la evolución
        del mundo que elegiste crear.
      </p>
      <p align="justify" style={{ color: "#8c52ff" }}>
        ¿Estás listo para ver tu futuro?
      </p>
    </Row>
  ),
};

const second_text = {
  eng: (
    <Row className="text-row">
      <header className="subtitle">
        <h3>The Cellular Automaton</h3>
        <h5>in Memory of John Conway</h5>
      </header>
      <p align="justify">
        A cellular automaton is a mathematical model for a dynamic system
        composed of a finite set of cells with two possible states: dead or
        alive. The model evolves at regular time intervals, which may be
        interpreted as steps or cycles. No intermediate steps exist between
        consecutive iterations as the automata is ruled by a discretized version
        of time, thus simplifying our continuous perception of time to a simple
        sequence of snapshots.
      </p>
      <p align="justify">
        The space on which the automata operate is divided into indivisible
        units called cells. The complexity of cellular automata is endless as
        they exist in all possible dimensions. In this project, we chose to
        explore the two-dimensional case.
      </p>
      <p align="justify">
        However, time provides an additional dimension to the system as cells
        grow and evolve. The rules governing this evolution can vary widely and
        they give rise to many different worlds.
      </p>
      <p align="justify">
        This mathematical concept was pioneered by John Horton Conway
        (1937-2020) with his Game of Life, and we decided to share his initial
        concept together with some other interesting renditions with this
        project. Nonetheless, the true beauty of this idea lies on its
        capability of encoding incredibly complex and almost natural behavior
        with extremely simple rules, while challenging our traditional notions
        about life and mathematics.
      </p>
    </Row>
  ),
  spa: (
    <Row className="text-row">
      <header className="subtitle">
        <h3>El Autómata Celular</h3>
        <h5>en memoria de John Conway</h5>
      </header>
      <p align="justify">
        Un autómata celular es un modelo matemático de un sistema dinámico
        compuesto por un conjunto finito de células con dos estados posibles:
        vivo o muerto. El modelo evoluciona a intervalos regulares de tiempo,
        que pueden ser interpretados como ciclos. No existen pasos intermedios
        entre iteraciones consecutivas ya que el autómata se rige por una
        versión discreta del tiempo, simplificando así nuestra percepción
        continua del mismo a una simple secuencia de instantes.
      </p>
      <p align="justify">
        El espacio en el que operan estos sistemas está formado por unidades
        indivisibles llamadas células. La complejidad de los autómatas celulares
        no tiene fin ya que existen en todas las dimensiones posibles. En este
        proyecto, elegimos explorar el caso de los autómatas bidimensionales.
      </p>
      <p align="justify">
        El tiempo proporciona una dimensión adicional al sistema a medida que
        las células crecen y evolucionan. Las reglas que gobiernan esta
        evolución pueden variar ampliamente dando lugar a mundos únicos.
      </p>
      <p align="justify">
        Este concepto matemático fue propuesto inicialmente por John Horton
        Conway (1937-2020) con su Juego de la Vida. Compartimos en este juego su
        concepto inicial junto con algunas otras interpretaciones en este
        proyecto. Sin embargo, la verdadera belleza de esta idea radica en su
        capacidad de codificar un comportamiento increíblemente complejo y casi
        natural con reglas extremadamente simples, mientras desafía nuestras
        nociones tradicionales sobre la vida y la matemática.
      </p>
    </Row>
  ),
};

const cred_title = {
  eng: "Credits",
  spa: "Créditos",
};

const cred_ale = {
  eng: "Artist: Alejandra Marinaro",
  spa: "Artista: Alejandra Marinaro",
};

const cred_fel = {
  eng: "Developer: Felipe Areces",
  spa: "Desarrollador: Felipe Areces",
};

const contact_us = {
  eng: "Contact us",
  spa: "Dejanos tu mensaje",
};

export default class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      mod_on: false,
    };
  }
  render() {
    return (
      <div key="main-div">
        <Navs
          activeLang={this.props.activeLang}
          langSwitch={this.props.langSwitch}
        />
        <Mod
          show={this.state.mod_on}
          hideFn={this.hideFn}
          activeLang={this.props.activeLang}
        />
        <Jumbo clickFn={this.clickFn} activeLang={this.props.activeLang} />
        <Container fluid className="container-t2">
          {first_text[this.props.activeLang]}
        </Container>
        <Container fluid className="container-t1">
          {second_text[this.props.activeLang]}
        </Container>
        <Container fluid className="container-t2">
          <Row className="text-row">
            <h3>{cred_title[this.props.activeLang]}</h3>
          </Row>
          <Row className="justify-content-center portrait-row">
            <Col
              xl={5}
              lg={{ span: 6, offset: 1 }}
              sm={{ span: 10, offset: 1 }}
              xs={12}
            >
              <ImText
                img={Alejandra}
                tag={cred_ale[this.props.activeLang]}
                hl={l_ale}
              />
            </Col>
            <Col
              xl={5}
              lg={{ span: 6, offset: 1 }}
              sm={{ span: 10, offset: 1 }}
              xs={12}
            >
              <ImText img={Felipe} tag={cred_fel[this.props.activeLang]} />
            </Col>
          </Row>
        </Container>
        <Container fluid className="container-t1">
          <Row className="text-row">
            <h3>{contact_us[this.props.activeLang]}</h3>
          </Row>
          <Row className="justify-content-center contact-row">
            <Col xs={10}>
              <ContactForm activeLang={this.props.activeLang} />
            </Col>
          </Row>
        </Container>
        <Container className="foot">
          <Row>
            <Col xs={12}>
              <footer>
                <p>© 2020 by Felipe Areces</p>
              </footer>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  clickFn = () => {
    this.setState({
      mod_on: true,
    });
  };

  hideFn = () => {
    this.setState({
      mod_on: false,
    });
  };
}

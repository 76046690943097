import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import "./comp.css";
import { Col, Row, Image } from "react-bootstrap";
import wait_eng from "../images/wait.png";
import wait_spa from "../images/wait_spa.png";
import Math from "math";

const wait = {
  eng: wait_eng,
  spa: wait_spa,
};

export default class Graph extends React.Component {
  static jsfiddleUrl = "https://jsfiddle.net/alidingling/xqjtetw0/";

  render() {
    let w = Math.max(this.props.w_width - 80, 350);
    if (this.props.c_energy === 0) {
      return (
        <Row className="justify-content-center">
          <Col lg={6} md={8} sm={10} xs={12}>
            <Image src={wait[this.props.activeLang]} fluid></Image>
          </Col>
        </Row>
      );
    } else {
      return (
        <div>
          <Row className="justify-content-center">
            <LineChart
              className="graph"
              width={w}
              height={300}
              data={this.props.record_arr}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="t" />
              <YAxis dataKey="Amplitude" />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="Amplitude"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </Row>
          <Row className="justify-content-center">
            <h6>Time (s)</h6>
          </Row>
        </div>
      );
    }
  }
}

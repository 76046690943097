import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import Navs from "../components/nav_var";
import RuleCheck from "../components/rule_check";
import Grid from "../components/grid";
import AudioBtn from "../components/audio";
import GameBtn from "../components/gamebtn";
import Graph from "../components/graph";
import MicRecorder from "mic-recorder-to-mp3";
import Math from "math";
import randomColor from "randomcolor";
import steps_eng from "../images/steps_eng.png";
import steps_spa from "../images/steps_spa.png";
import { isMobile } from "react-device-detect";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const steps = {
  eng: steps_eng,
  spa: steps_spa,
};

export default class Game extends React.Component {
  // Game constructor
  constructor() {
    super();
    this.rows = 20;
    this.cols = 15;
    this.ini_speed = 220;
    this.seed_percent = 0.4;
    this.all_zeros = true;
    this.state = {
      checks: {
        "checkbox-1": true,
        "checkbox-2": false,
        "checkbox-3": false,
        "checkbox-4": false,
      },
      generations: 0,
      game_arr: new Array(this.rows)
        .fill(0)
        .map(() => new Array(this.cols).fill(0)),
      isRecording: false,
      isBlocked: false,
      record_arr: [],
      c_energy: 0,
      w_width: 0,
      w_height: 0,
      gen_col: { 0: "#f1f1f1", 1: "#8c52ff" },
      isPlaying: false,
      isSeeded: false,
      intervalId: null,
      speed: this.ini_speed,
      canSeed: true,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  // Page render
  render() {
    return (
      <div key="main-div">
        <Navs
          activeLang={this.props.activeLang}
          langSwitch={this.props.langSwitch}
        />
        <div className="divisor" xs={12} style={{ height: "20px" }}></div>
        <Container fluid>
          <RuleCheck
            checks={this.state.checks}
            click_fn={this.cb_click}
            activeLang={this.props.activeLang}
            gen_col={this.state.gen_col}
            generations={this.state.generations}
          />
        </Container>
        <Container fluid>
          <Row className="justify-content-center">
            <Grid
              game_arr={this.state.game_arr}
              rows={this.rows}
              cols={this.cols}
              gen_col={this.state.gen_col}
              manualSeed={this.manualSeed}
              activeLang={this.props.activeLang}
            />
          </Row>
        </Container>
        <Container fluid>
          <div xs={12} style={{ height: "20px" }}></div>
          <Graph
            activeLang={this.props.activeLang}
            record_arr={this.state.record_arr}
            w_width={this.state.w_width}
            c_energy={this.state.c_energy}
          />
        </Container>
        <Container fluid>
          <div xs={12} style={{ height: "20px" }}></div>
          <Row className="justify-content-center">
            <Col md={{ span: 3 }} sm={6} xs={8}>
              <AudioBtn
                activeLang={this.props.activeLang}
                isRecording={this.state.isRecording}
                c_energy={this.state.c_energy}
                isBlocked={this.state.isBlocked}
                start={this.start}
                stop={this.stop}
                gridSeed={this.gridSeed}
                isPlaying={this.state.isPlaying}
              />
            </Col>
            <Col md={{ span: 3, offset: 1 }} sm={6} xs={8}>
              <GameBtn
                activeLang={this.props.activeLang}
                isSeeded={this.state.isSeeded}
                isPlaying={this.state.isPlaying}
                playBtn={this.playBtn}
                pauseBtn={this.stopBtn}
                clearBtn={this.clearBtn}
              />
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row className="justify-content-center">
            <Col md={8} sm={12}>
              <Image src={steps[this.props.activeLang]} fluid></Image>
            </Col>
          </Row>
        </Container>
        <Container className="foot">
          <Row>
            <Col xs={12}>
              <footer>
                <p>© 2020 by Felipe Areces</p>
              </footer>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  // Initialization management
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    navigator.mediaDevices.getUserMedia(
      { audio: true },
      () => {
        console.log("Permission Granted");
        this.setState({ isBlocked: false });
      },
      () => {
        console.log("Permission Denied");
        this.setState({ isBlocked: true });
      }
    );
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  // Update state recording window size
  updateWindowDimensions() {
    // Adjust for desired whitespace on each side
    this.cols = Math.max(Math.floor(window.innerWidth / 23 - 10), 13);
    if (!isMobile) {
      let n_game_arr = new Array(this.rows)
        .fill(0)
        .map(() => new Array(this.cols).fill(0));
      this.setState({
        // Adjust for desired whitespace on each side
        w_width: window.innerWidth - 80,
        w_height: window.innerHeight,
        game_arr: n_game_arr,
      });
    } else {
      this.setState({
        // Adjust for desired whitespace on each side
        w_width: window.innerWidth - 80,
        w_height: window.innerHeight,
      });
    }
  }

  // Checkboxes update
  cb_click = (e) => {
    if (!this.state.isPlaying) {
      let clicked = e.target.id;
      let n_checks = {};
      for (const [key] of Object.entries(this.state.checks)) {
        if (key === clicked) {
          n_checks[key] = true;
        } else {
          n_checks[key] = false;
        }
      }
      this.setState({ checks: n_checks });
    }
  };

  // Start recording audio
  start = () => {
    if (this.state.isBlocked) {
      console.log("Permission Denied");
    } else {
      Mp3Recorder.start()
        .then(() => {
          this.setState({ isRecording: true });
        })
        .catch((e) => console.error(e));
    }
  };

  // Stop recording audio and update
  stop = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        let obj_arr = [];
        let fl_buffer = this.flat_arr(buffer);
        let energy = 0;
        const inter = 1555;
        const max_l = 101;
        //console.log(fl_buffer.length);
        for (let i = 0; i < fl_buffer.length; i++) {
          if (i % inter === 0 && obj_arr.length < max_l) {
            obj_arr.push({
              t: Math.round((i / inter) * 10) / 100,
              Amplitude: fl_buffer[i],
            });
            energy += Math.pow(fl_buffer[i], 2);
          }
        }
        this.setState({
          record_arr: obj_arr,
          isRecording: false,
          c_energy: energy,
        });
        //console.log(this.state.c_energy);
      })
      .catch((e) => console.log(e));
  };

  // Grid seeding algorithm
  gridSeed = () => {
    let s1 = Math.floor(this.state.c_energy / 1000);
    let s2 = this.state.c_energy % 1000;
    const n_seeds = this.rows * this.cols * this.seed_percent;
    console.log(n_seeds);
    let c_game_arr = new Array(this.rows)
      .fill(0)
      .map(() => new Array(this.cols).fill(0));
    for (let i = 0; i < n_seeds; i++) {
      let r_n = Math.floor(this.random(s1 + i) * this.rows);
      let c_n = Math.floor(this.random(s2 + i) * this.cols);
      c_game_arr[r_n][c_n] = 1;
    }
    this.setState({
      game_arr: c_game_arr,
      isSeeded: true,
      generations: 1,
    });
  };

  manualSeed = (row, col) => {
    if (this.state.isPlaying || !this.state.canSeed) {
      return;
    }
    let c_game_arr = JSON.parse(JSON.stringify(this.state.game_arr));
    let n_gen = null;
    if (c_game_arr[row][col] === 0) {
      c_game_arr[row][col] = 1;
      n_gen = 1;
    } else {
      c_game_arr[row][col] = 0;
      n_gen = c_game_arr.reduce((a, b) => a + b, 0) === 0 ? 1 : 0;
    }
    this.setState({
      game_arr: c_game_arr,
      isSeeded: true,
      generations: n_gen,
    });
  };

  // Gameplay
  playBtn = () => {
    let n_intervalId = null;
    if (this.state.isPlaying) {
      return;
    } else if (this.state.checks["checkbox-1"]) {
      n_intervalId = setInterval(this.life, this.state.speed);
    } else if (this.state.checks["checkbox-2"]) {
      n_intervalId = setInterval(this.deadlock, this.state.speed);
    } else if (this.state.checks["checkbox-3"]) {
      n_intervalId = setInterval(this.seeds, this.state.speed);
    } else {
      n_intervalId = setInterval(this.highLife, this.state.speed);
    }
    this.setState({
      intervalId: n_intervalId,
      isPlaying: true,
      canSeed: false,
    });
  };

  stopBtn = () => {
    clearInterval(this.state.intervalId);
    this.setState({
      intervalId: null,
      isPlaying: false,
    });
  };

  clearBtn = () => {
    if (!this.state.isPlaying) {
      let n_game_arr = new Array(this.rows)
        .fill(0)
        .map(() => new Array(this.cols).fill(0));
      let c_gen_col = { 0: "#f1f1f1", 1: "#8c52ff" };
      this.setState({
        game_arr: n_game_arr,
        canSeed: true,
        isSeeded: false,
        generations: 0,
        gen_col: c_gen_col,
      });
    }
  };

  life = () => {
    let n_gen = this.state.generations + 1;
    let c_gen_col = JSON.parse(JSON.stringify(this.state.gen_col));
    c_gen_col[n_gen] = randomColor();
    let n_arr = this.n_sum();
    let c_game_arr = new Array(this.rows)
      .fill(0)
      .map(() => new Array(this.cols).fill(0));
    for (let i = 0; i < this.rows; i++) {
      for (let j = 0; j < this.cols; j++) {
        if (
          this.state.game_arr[i][j] !== 0 &&
          (n_arr[i][j] === 2 || n_arr[i][j] === 3)
        ) {
          c_game_arr[i][j] = this.state.game_arr[i][j];
        } else if (this.state.game_arr[i][j] === 0 && n_arr[i][j] === 3) {
          c_game_arr[i][j] = n_gen;
        }
      }
    }
    this.setState({
      game_arr: c_game_arr,
      gen_col: c_gen_col,
      generations: n_gen,
    });
    if (this.all_zeros) {
      this.stopBtn();
      this.clearBtn();
    }
  };

  highLife = () => {
    //console.log("HighLife");
    let n_gen = this.state.generations + 1;
    let c_gen_col = JSON.parse(JSON.stringify(this.state.gen_col));
    c_gen_col[n_gen] = randomColor();
    let n_arr = this.n_sum();
    let c_game_arr = new Array(this.rows)
      .fill(0)
      .map(() => new Array(this.cols).fill(0));
    for (let i = 0; i < this.rows; i++) {
      for (let j = 0; j < this.cols; j++) {
        if (
          this.state.game_arr[i][j] !== 0 &&
          (n_arr[i][j] === 2 || n_arr[i][j] === 3)
        ) {
          c_game_arr[i][j] = this.state.game_arr[i][j];
        } else if (
          this.state.game_arr[i][j] === 0 &&
          (n_arr[i][j] === 3 || n_arr[i][j] === 6)
        ) {
          c_game_arr[i][j] = n_gen;
        }
      }
    }
    this.setState({
      game_arr: c_game_arr,
      gen_col: c_gen_col,
      generations: n_gen,
    });
    if (this.all_zeros) {
      this.stopBtn();
      this.clearBtn();
    }
  };

  seeds = () => {
    //console.log("HighLife");
    let n_gen = this.state.generations + 1;
    let c_gen_col = JSON.parse(JSON.stringify(this.state.gen_col));
    c_gen_col[n_gen] = randomColor();
    let n_arr = this.n_sum();
    let c_game_arr = new Array(this.rows)
      .fill(0)
      .map(() => new Array(this.cols).fill(0));
    for (let i = 0; i < this.rows; i++) {
      for (let j = 0; j < this.cols; j++) {
        if (this.state.game_arr[i][j] === 0 && n_arr[i][j] === 2) {
          c_game_arr[i][j] = n_gen;
        }
      }
    }
    this.setState({
      game_arr: c_game_arr,
      gen_col: c_gen_col,
      generations: n_gen,
    });
    if (this.all_zeros) {
      this.stopBtn();
      this.clearBtn();
    }
  };

  deadlock = () => {
    //console.log("HighLife");
    let n_gen = this.state.generations + 1;
    let c_gen_col = JSON.parse(JSON.stringify(this.state.gen_col));
    c_gen_col[n_gen] = randomColor();
    let n_arr = this.n_sum();
    let c_game_arr = new Array(this.rows)
      .fill(0)
      .map(() => new Array(this.cols).fill(0));
    for (let i = 0; i < this.rows; i++) {
      for (let j = 0; j < this.cols; j++) {
        if (
          this.state.game_arr[i][j] !== 0 &&
          (n_arr[i][j] === 1 || n_arr[i][j] === 2 || n_arr[i][j] === 4)
        ) {
          c_game_arr[i][j] = this.state.game_arr[i][j];
        } else if (
          this.state.game_arr[i][j] === 0 &&
          (n_arr[i][j] === 1 || n_arr[i][j] === 2)
        ) {
          c_game_arr[i][j] = n_gen;
        }
      }
    }
    this.setState({
      game_arr: c_game_arr,
      gen_col: c_gen_col,
      generations: n_gen,
    });
    if (this.all_zeros) {
      this.stopBtn();
      this.clearBtn();
    }
  };

  // Utilities
  flat_arr(arr) {
    let n_arr = [];
    for (let i = 0; i < arr.length; i++) {
      for (let j = 0; j < arr[i].length; j++) {
        n_arr.push(arr[i][j]);
      }
    }
    return n_arr;
  }

  random(seed) {
    let x = Math.sin(seed) * 10000;
    return x - Math.floor(x);
  }

  indexRound(ind, m) {
    if (ind < 0) {
      return 0;
    } else if (ind > m) {
      return m;
    } else {
      return ind;
    }
  }

  n_sum = () => {
    let s_arr = new Array(this.rows)
      .fill(0)
      .map(() => new Array(this.cols).fill(0));
    let n_all_zeros = true;
    for (let i = 0; i < this.rows; i++) {
      for (let j = 0; j < this.cols; j++) {
        if (this.state.game_arr[i][j] !== 0) {
          n_all_zeros = false;
          for (
            let i1 = this.indexRound(i - 1, this.rows);
            i1 < this.indexRound(i + 2, this.rows);
            i1++
          ) {
            for (
              let j1 = this.indexRound(j - 1, this.cols);
              j1 < this.indexRound(j + 2, this.cols);
              j1++
            ) {
              if (i1 !== i || j1 !== j) {
                s_arr[i1][j1] += 1;
              }
            }
          }
        }
      }
    }
    this.all_zeros = n_all_zeros;
    return s_arr;
  };
}

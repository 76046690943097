import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import RuleNav from "./rulenav";
import "./comp.css";

const gl_eng = [
  {
    name: "Survive",
    eventK: "sur_gl",
    img: "sur_gl",
    text:
      "Any living cell with two or three living neighbours will survive and live on to the next generation.",
  },
  {
    name: "Die",
    eventK: "die_gl",
    img: "die_gl",
    text:
      "Any living cell will die from isolation by having fewer than two living neighbours, or from overcrowding by having more than three.",
  },
  {
    name: "Born",
    eventK: "born_gl",
    img: "born_gl",
    text:
      "Any dead cell with exactly three living neighbours becomes a live cell, as if by reproduction.",
  },
];

const gl_spa = [
  {
    name: "Sobrevivir",
    eventK: "sur_gl",
    img: "sur_gl",
    text:
      "Cualquier célula viva con dos o tres vecinos vivos sobrevivirá y vivirá hasta la próxima generación.",
  },
  {
    name: "Morir",
    eventK: "die_gl",
    img: "die_gl",
    text:
      "Cualquier célula viva morirá por aislamiento al tener menos de dos vecinos vivos, o por hacinamiento al tener más de tres.",
  },
  {
    name: "Nacer",
    eventK: "born_gl",
    img: "born_gl",
    text:
      "Cualquier célula muerta con exactamente tres vecinos vivos se convierte en una célula viva, por reproducción.",
  },
];

const dl_eng = [
  {
    name: "Survive",
    eventK: "sur_dl",
    img: "sur_dl",
    text:
      "Any living cell with one, two or four living neighbours will survive and live on to the next generation.",
  },
  {
    name: "Die",
    eventK: "die_dl",
    img: "die_dl",
    text:
      "Any living cell will die by having three living neighbours or more than five.",
  },
  {
    name: "Born",
    eventK: "born_dl",
    img: "born_dl",
    text:
      "Any dead cell with one or two liviving neighbours becomes a living cell, as if by reproduction.",
  },
];

const dl_spa = [
  {
    name: "Sobrevivir",
    eventK: "sur_dl",
    img: "sur_dl",
    text:
      "Cualquier célula viva con uno, dos o cuatro vecinos vivos sobrevivirá y vivirá hasta la próxima generación.",
  },
  {
    name: "Morir",
    eventK: "die_dl",
    img: "die_dl",
    text:
      "Cualquier célula viva morirá al tener tres vecinos vivos, o al tener más de cinco.",
  },
  {
    name: "Nacer",
    eventK: "born_dl",
    img: "born_dl",
    text:
      "Cualquier célula muerta con uno o dos vecinos vivos se convierte en una célula viva, por reproducción.",
  },
];

const s_eng = [
  {
    name: "Born",
    eventK: "born_s",
    img: "born_s",
    text:
      "Cells survive only one generation. Any dead cell with exactly two living neighbours becomes a living cell, as if by reproduction.",
  },
];

const s_spa = [
  {
    name: "Nacer",
    eventK: "born_s",
    img: "born_s",
    text:
      "Las células sobreviven solo una generación. Cualquier célula muerta con exactamente dos vecinos vivos se convierte en una célula viva, por reproducción.",
  },
];

const hl_eng = [
  {
    name: "Survive",
    eventK: "sur_hl",
    img: "sur_hl",
    text:
      "Any living cell with two or three living neighbours will survive and live on to the next generation.",
  },
  {
    name: "Die",
    eventK: "die_hl",
    img: "die_hl",
    text:
      "Any living cell will die by having one, four, five or more than six living neighbours.",
  },
  {
    name: "Born",
    eventK: "born_hl",
    img: "born_hl",
    text:
      "Any dead cell with exactly three or six living neighbours becomes a living cell, as if by reproduction.",
  },
];

const hl_spa = [
  {
    name: "Sobrevivir",
    eventK: "sur_hl",
    img: "sur_hl",
    text:
      "Cualquier célula viva con dos o tres vecinos vivos sobrevivirá y vivirá hasta la próxima generación.",
  },
  {
    name: "Morir",
    eventK: "die_hl",
    img: "die_hl",
    text:
      "Cualquier célula viva morirá al tener uno, cuatro, cinco o más de seis vecinos vivos.",
  },
  {
    name: "Nacer",
    eventK: "born_hl",
    img: "born_hl",
    text:
      "Cualquier célula muerta con exactamente tres o seis vecinos vivos se convierte en una célula viva, por reproducción.",
  },
];

const long_first_tab = {
  eng: "Game of Life",
  spa: "Juego de la Vida",
};

const short_first_tab = {
  eng: "Life",
  spa: "Life",
};

const games = {
  gl: {
    eng: gl_eng,
    spa: gl_spa,
  },
  dl: {
    eng: dl_eng,
    spa: dl_spa,
  },
  s: {
    eng: s_eng,
    spa: s_spa,
  },
  hl: {
    eng: hl_eng,
    spa: hl_spa,
  },
};

export default class RuleComb extends React.Component {
  render() {
    let first_tab = window.innerWidth > 555 ? long_first_tab : short_first_tab;
    let dead_lock = window.innerWidth > 420 ? "Deadlock" : "DLock";
    let high_life = window.innerWidth > 330 ? "Highlife" : "HLife";
    return (
      <Tabs defaultActiveKey="game_of_life" id="uncontrolled-tab-example">
        <Tab eventKey="game_of_life" title={first_tab[this.props.activeLang]}>
          <RuleNav tabObj={games["gl"][this.props.activeLang]} />
        </Tab>
        <Tab eventKey="deadlock" title={dead_lock}>
          <RuleNav tabObj={games["dl"][this.props.activeLang]} />
        </Tab>
        <Tab eventKey="seeds" title="Seeds">
          <RuleNav tabObj={games["s"][this.props.activeLang]} />
        </Tab>
        <Tab eventKey="highlife" title={high_life}>
          <RuleNav tabObj={games["hl"][this.props.activeLang]} />
        </Tab>
      </Tabs>
    );
  }
}

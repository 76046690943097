import React from "react";
import { Form, Row } from "react-bootstrap";
import "./comp.css";
import StaticBox from "./static_box";

const lg_title = {
  eng: "Last Generation:",
  spa: "Última Generación:",
};

class RuleCheck extends React.Component {
  render() {
    const rules = [
      { lab: "Life", id: 1 },
      { lab: window.innerWidth < 375 ? "DLock" : "Deadlock", id: 2 },
      { lab: "Seeds", id: 3 },
      { lab: "Highlife", id: 4 },
    ];
    const titles = {
      "checkbox-1": {
        eng: "Life",
        spa: "Juego de la Vida",
      },
      "checkbox-2": {
        eng: "Deadlock",
        spa: "Deadlock",
      },
      "checkbox-3": {
        eng: "Seeds",
        spa: "Seeds",
      },
      "checkbox-4": {
        eng: "Highlife",
        spa: "Highlife",
      },
    };
    let act_key = this.getKeyByValue(this.props.checks, true);
    return (
      <div>
        <Row className="justify-content-center">
          <Form>
            <div key={`inline-checkbox`} className="mb-3 check-outbox">
              {rules.map((obj) => (
                <Form.Check
                  inline
                  label={obj.lab}
                  onChange={this.props.click_fn}
                  checked={this.props.checks[`checkbox-${obj.id}`]}
                  type="radio"
                  id={`checkbox-${obj.id}`}
                  key={`checkbox-${obj.id}`}
                />
              ))}
            </div>
          </Form>
        </Row>
        <Row className="justify-content-center">
          <h4 className="title">{titles[act_key][this.props.activeLang]}</h4>
        </Row>
        <Row className="justify-content-center statbox-row">
          <h5 className="title">{lg_title[this.props.activeLang]}</h5>
          <StaticBox
            color={this.props.gen_col[this.props.generations]}
            boxClass="lg-box"
          />
        </Row>
      </div>
    );
  }

  getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }
}

export default RuleCheck;
